<template>
  <div ref="chart" class="chart" :style="{ height: height }"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "Chart",
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
      default: "450px",
    },
  },
  data() {
    return {};
  },
  watch: {
    options: {
      handler() {
        this.initChart();
      },
    },
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart, null, {
        devicePixelRatio: 2.5,
      });
      chart.setOption(this.options, true);
      setTimeout(() => {
        window.onresize = function () {
          chart.resize();
        };
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 100%;
}
</style>
