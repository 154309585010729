<template>
  <div class="newExamStat">
    <div class="card withTag">
      <tag />
      <div class="title-box">
        <div></div>
        <div class="title">
          {{ topData.name }}
          <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
            {{ getExamType(topData.type) }}
          </div>
          <div v-if="topData.ruleId > 0" class="other">新高考</div>
        </div>
      </div>
      <div class="sub-title-box">
        <div class="back" @click="back">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        <div class="sub-title">
          <div>年级：{{ topData.year }}级</div>
          <div>时间：{{ topData.examDate }}</div>
        </div>
        <div></div>
      </div>
      <div class="model-title">新高考统计</div>
    </div>
    <div class="card">
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane label="赋分等级分布" name="0">
          <teaching-class />
          <administration />
        </el-tab-pane>
        <el-tab-pane label="基础指标汇总" name="1">
          <Basic></Basic>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getnewgaokaoreporttopinfo } from "@/core/api/academic/newExam";
import { examTypeOptions } from "@/core/util/constdata";
import tag from "../components/tag.vue";
import teachingClass from "./components/teachingClass.vue";
import administration from "./components/administration.vue";
import Basic from "./components/basic.vue";
export default {
  name: "NewExamStat",
  components: {
    tag,
    teachingClass,
    administration,
    Basic,
  },
  data() {
    return {
      statId: this.$route.query.id,
      topData: {},
      type: "0",
    };
  },
  mounted() {
    this.getTopData();
  },
  methods: {
    handleClick() {},
    async getTopData() {
      const res = await getnewgaokaoreporttopinfo({ id: this.statId });
      this.topData = res.data.data;
    },
    back() {
      this.$router.go(-1);
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.newExamStat {
  width: 1200px;
  margin: 0 auto;
  .withTag {
    position: relative;
  }
  .title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 18px;
      color: #101011;
      text-align: center;
      font-weight: 600;
      margin: 18px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .sub-tag {
        margin-left: 24px;
        height: 26px;
        box-sizing: border-box;
        padding: 0 12px;
        line-height: 26px;
        font-size: 14px;
        border: 1px solid;
      }
      .other {
        color: #3f87f4;
        border-color: #3f87f4;
      }
      .general {
        color: #ffaf6a;
        border-color: #ffaf6a;
      }
    }
    div {
      min-width: 80px;
    }
  }

  .model-title {
    font-size: 18px;
    color: #3f87f4;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    font-weight: 550;
    margin-bottom: 24px;
    &::before {
      content: "";
      width: 64px;
      height: 2px;
      background-color: #e9fff5;
      position: absolute;
      left: -72px;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      width: 64px;
      height: 2px;
      background-color: #e9fff5;
      position: absolute;
      right: -72px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .sub-title-box {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back {
      font-size: 14px;
      color: #5e6166;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .sub-title {
      font-size: 14px;
      color: #5e6166;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        margin-right: 24px;
        width: fit-content;
        text-align: right;
      }
      & :last-child {
        margin: 0;
        text-align: left;
      }
    }
    div {
      min-width: 80px;
    }
  }
}
</style>
