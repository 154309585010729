<template>
  <div
    ref="teachingClass"
    v-lazyChart="{ fn: getstatapsubjectoptionData }"
    class="teachingClass"
  >
    <div class="section-title">教学班</div>
    <el-tabs
      v-model="subjectId"
      style="margin-bottom: 24px"
      @tab-click="handleChange"
    >
      <el-tab-pane
        v-for="(item, index) in tabs"
        :key="index"
        :label="item.name"
        :name="item.id.toString()"
      ></el-tab-pane>
    </el-tabs>
    <chart-component :options="option" :height="'550px'"></chart-component>
  </div>
</template>
<script>
import { getstatapsubjectoption } from "@/core/api/academic/common";
import { teachclass } from "@/core/api/academic/newExam";
import chartComponent from "./chart.vue";
export default {
  name: "TeachingClass",
  components: {
    chartComponent,
  },
  data() {
    return {
      statId: this.$route.query.id,
      tabs: [],
      subjectId: "",
      option: {},
    };
  },

  methods: {
    async getstatapsubjectoptionData(loadingInstance, io) {
      const res = await getstatapsubjectoption({ id: this.statId });
      this.tabs = res.data.data;
      this.subjectId = res.data.data.length && res.data.data[0].id.toString();
      this.getTeachingClass();
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.teachingClass);
      });
    },
    async getTeachingClass() {
      const res = await teachclass({
        id: this.statId,
        subjectId: this.subjectId,
      });
      let classNum =
        res.data.data.length &&
        res.data.data[0].classes.map((item) => item.classNum);

      this.option = {
        title: {
          text: "图. 各班级赋分等级人数分布（教学班）",
          left: "center",
          top: "5%",
          textStyle: {
            color: "#333",
            fontSize: 14,
            fontWeight: 300,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "12%",
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: res.data.data.map((item) => item.name),
          axisLine: { show: false },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
        },
        series: classNum.map((item) => {
          return {
            name: item,
            type: "line",
            smooth: true,
            data: res.data.data.map((i) => {
              const data = i.classes.find((it) => it.classNum == item);
              return data.total;
            }),
          };
        }),
      };
    },
    handleChange() {
      this.getTeachingClass();
    },
  },
};
</script>
<style lang="scss" scoped>
.teachingClass {
  box-sizing: border-box;
  padding-top: 24px;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}
::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}
</style>
