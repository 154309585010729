<template>
  <div
    ref="administration"
    v-lazyChart="{ fn: getstatclassoptionData }"
    class="administration"
  >
    <div class="section-title">行政班</div>
    <el-tabs
      v-model="classNum"
      style="margin-bottom: 24px"
      @tab-click="handleChange"
    >
      <el-tab-pane
        v-for="(item, index) in tabs"
        :key="index"
        :label="item.name"
        :name="item.name"
      ></el-tab-pane>
    </el-tabs>
    <chart-component
      :options="options"
      :height="'550px'"
      style="margin-bottom: 24px"
    ></chart-component>
    <div class="title">附表.划线标准</div>
    <el-table v-loading="loading" :data="tableData" border style="width: 100%">
      <el-table-column
        prop="name"
        fixed
        width="100"
        label="等级"
        align="center"
      />
      <el-table-column
        prop="apIntervalMin"
        fixed
        label="赋分区间"
        width="120"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.type == 1">
            {{ row.apIntervalMax }}-{{ row.apIntervalMin }}</span
          >
          <span v-if="row.type == 2"> {{ row.apIntervalMax }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in subjects"
        :key="index"
        :label="item.subjectName"
        align="center"
      >
        <el-table-column label="有效分" width="120" align="center">
          <template slot-scope="{ row }">
            {{ row.subjects[index].ep }}
          </template>
        </el-table-column>
        <el-table-column label="人数" width="120" align="center">
          <template slot-scope="{ row }">
            {{ row.subjects[index].total }}
          </template>
        </el-table-column>
        <el-table-column label="划线比例" width="120" align="center">
          <template slot-scope="{ row }">
            {{ row.subjects[index].apProportion }}
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          width="120"
          label="实际比例"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.subjects[index].realProportion }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { getstatclassoption } from "@/core/api/academic/common";
import { getAdministrativeClass, getAplist } from "@/core/api/academic/newExam";
import chartComponent from "./chart.vue";
export default {
  name: "Administration",
  components: {
    chartComponent,
  },
  data() {
    return {
      classNum: "",
      tabs: [],
      statId: this.$route.query.id,
      options: {},
      tableData: [],
      subjects: [],
      loading: false,
    };
  },
  mounted() {
    this.getAplistData();
  },
  methods: {
    handleChange() {
      this.getAdministrativeClassData();
    },
    async getstatclassoptionData(loadingInstance, io) {
      const res = await getstatclassoption({ id: this.statId });
      this.tabs = res.data.data;
      this.classNum = res.data.data.length && res.data.data[0].name;
      this.getAdministrativeClassData();
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.administration);
      });
    },
    async getAdministrativeClassData() {
      const res = await getAdministrativeClass({
        id: this.statId,
        classNum: this.classNum,
      });
      const yAxis =
        res.data.data.length &&
        res.data.data[0].subjects.map((item) => item.subjectName);
      const allData = res.data.data
        .map((item) => {
          return item.subjects.map((it) => it.total);
        })
        .flat();
      const max = Math.max(...allData);
      const min = Math.min(...allData);
      const data = res.data.data
        .map((item, index) => {
          return item.subjects.map((it, ind) => {
            return [index, ind, it.total];
          });
        })
        .flat();
      this.options = {
        title: {
          text: `图. "${this.classNum}"班 各学科赋分等级人数分布`,
          left: "center",

          textStyle: {
            color: "#333",
            fontSize: 14,
            fontWeight: 300,
          },
        },
        tooltip: {
          position: "top",
        },
        grid: {
          height: "75%",
          top: "10%",
        },
        xAxis: {
          type: "category",
          data: res.data.data.map((item) => item.name),
          splitArea: {
            show: true,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
        },
        yAxis: {
          type: "category",
          data: [...yAxis],
          splitArea: {
            show: true,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
        },
        visualMap: {
          min: min,
          max: max,
          calculable: true,
          orient: "horizontal",
          left: "center",
          bottom: "15%",
          show: false,
          color: ["#539DE6", "#fff"],
        },
        series: [
          {
            name: "",
            type: "heatmap",
            data: [...data],
            label: {
              show: true,
              color: "#fff",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    async getAplistData() {
      this.loading = true;
      const res = await getAplist({ id: this.statId });
      this.tableData = res.data.data;
      this.subjects = res.data.data.length && res.data.data[0].subjects;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.administration {
  margin-top: 24px;
  .title {
    font-size: 14px;
    color: #333;
    font-weight: 300;
    margin-bottom: 24px;
    text-align: center;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}
::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}
::v-deep .is-scrollable {
  display: flex;
  align-items: center;
}
</style>
