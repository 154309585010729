<template>
  <div v-loading="loading" class="basic">
    <div class="row">
      <div class="label">班 级：</div>
      <el-checkbox
        v-model="administrative"
        class="single"
        @change="administrativeChange"
        >行政班</el-checkbox
      >
      <el-checkbox-group v-model="subjects" @change="subjectsChange">
        <el-checkbox
          v-for="(item, index) in subjectOptions"
          :key="index"
          :label="item.subjectId"
          >{{ item.subjectName }}教学班</el-checkbox
        >
      </el-checkbox-group>
    </div>
    <div class="row">
      <div class="label">统计维度：</div>
      <el-checkbox v-model="average">平均分</el-checkbox>
    </div>
    <div class="tableList">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="name" label=" " width="180" align="center">
        </el-table-column>
        <el-table-column label="总分" align="center">
          <el-table-column
            prop="totalStatTotal"
            label="统计人数"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.totalStatTotal || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="average"
            prop="totalScoreOri"
            label="原始分（平均分）"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.totalScoreOri || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="average"
            prop="totalScoreAp"
            label="赋分（平均分）"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.totalScoreAp || "-" }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="average" label="非选考科目" align="center">
          <el-table-column
            prop="requiredScoreOri"
            label="原始分（平均分）"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.requiredScoreOri || "-" }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="average" label="选考组合" align="center">
          <el-table-column
            prop="electiveScoreOri"
            label="原始分（平均分）"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.electiveScoreOri || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="electiveScoreAp"
            label="赋分（平均分）"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.electiveScoreAp || "-" }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableSubject"
          :key="index"
          :label="item.subjectName"
          align="center"
        >
          <el-table-column label="统计人数" width="180" align="center">
            <template slot-scope="{ row }">
              {{ row.subjects[index].statTotal || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="average"
            label="原始分（平均分）"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.subjects[index].scoreOri || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="average && item.isAp"
            label="赋分（平均分）"
            width="180"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.subjects[index].scoreAp || "-" }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getngkreportbasicindicator } from "@/core/api/academic/newExam";
export default {
  name: "Basic",
  data() {
    return {
      statId: this.$route.query.id,
      subjects: [],
      subjectList: [],
      average: true,
      loading: false,
      administrative: false,
      tableData: [],
      administrativeClasses: [],
      tableSubject: [],
      grade: [],
      subjectOptions: [],
      administrativeData: [],
    };
  },
  mounted() {
    this.getngkreportbasicindicatorData();
  },
  methods: {
    async getngkreportbasicindicatorData() {
      this.loading = true;
      const res = await getngkreportbasicindicator({ id: this.statId });
      const { administrativeClasses, grade, subjects } = res.data.data;
      this.tableData = [grade];
      this.grade = grade;
      this.administrativeClasses = [...administrativeClasses];
      this.subjectOptions = [...subjects];
      this.tableSubject = grade.subjects;
      this.loading = false;
    },
    administrativeChange() {
      this.administrativeData = this.administrative
        ? this.administrativeClasses
        : [];
      this.tableData = [
        this.grade,
        ...this.administrativeData,
        ...this.subjectList,
      ];
    },
    subjectsChange() {
      const data = this.subjectOptions.filter((item) =>
        this.subjects.includes(item.subjectId)
      );
      this.subjectList = data.map((item) => item.teachingClasses).flat();
      this.tableData = [
        this.grade,
        ...this.administrativeData,
        ...this.subjectList,
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.basic {
  box-sizing: border-box;
  padding: 24px 0;
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: #333;
    .label {
      font-size: 14px;
      width: 80px;
    }
    .single {
      margin-right: 30px;
    }
  }
}
</style>
